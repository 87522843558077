import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboard"],
        },
      },
      {
        path: "/calendar",
        name: "calendar",
        component: () => import("@/views/Calendar.vue"),
        beforeEnter: (to, from, next) => {
          if (store.getters.isUserAuthenticated) {
            next();
          } else {
            next("/sign-in");
          }
        },
        meta: {
          pageTitle: "Calendar",
          breadcrumbs: ["Calendar"],
        },
      },
      {
        path: "/faq",
        name: "faq",
        component: () => import("@/views/Faq.vue"),
        meta: {
          pageTitle: "FAQ",
          breadcrumbs: ["Faq"],
        },
      },
      {
        path: "/crafted/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        meta: {
          breadcrumbs: ["Crafted", "Account"],
        },
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/crafted/account/Overview.vue"),
            meta: {
              pageTitle: "Overview",
            },
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
            meta: {
              pageTitle: "Settings",
            },
          },
          {
            path: "service",
            name: "account-service",
            component: () => import("@/views/crafted/account/Service.vue"),
            meta: {
              pageTitle: "Service",
            },
          },
          {
            path: "agreement",
            name: "account-agreement",
            component: () => import("@/views/crafted/account/Agreement.vue"),
            meta: {
              pageTitle: "Agreement",
              breadcrumbs: ["account"],
              boardKind: 4,
            },
          },
          {
            path: "inspection",
            name: "account-inspection",
            component: () => import("@/views/crafted/account/Inspection.vue"),
            meta: {
              pageTitle: "Inspection",
              breadcrumbs: ["inspection"],
              boardKind: 5,
            },
          },
          {
            path: "/account/writeDoc",
            name: "agreementWriteDoc",
            component: () => import("@/views/board/WriteDoc.vue"),
            meta: {
              pageTitle: "Write",
              breadcrumbs: ["account", "Write"],
              boardKind: 4,
            },
          },
          {
            path: "/account/doc",
            name: "aggrementDoc",
            component: () => import("@/views/board/Doc.vue"),
            meta: {
              pageTitle: "Doc",
              breadcrumbs: ["account", "Doc"],
              boardKind: 4,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  {
    path: "/serviceList",
    redirect: "/serviceList",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/serviceList/reflection",
        name: "serviceList",
        component: () => import("@/views/service/ServiceList.vue"),
        meta: {
          pageTitle: "Service List",
          breadcrumbs: ["Service List"],
        },
      },
      {
        path: "/serviceList/assistance",
        name: "assistance",
        component: () => import("@/views/service/Assistance.vue"),
        meta: {
          pageTitle: "Assistance",
          breadcrumbs: ["Service List", "Assistance"],
        },
      },
    ],
  },
  {
    path: "/dailyReport",
    redirect: "/dailyReport",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dailyReport",
        name: "dailyReport",
        component: () => import("@/views/report/DailyReport.vue"),
        meta: {
          pageTitle: "Daily Report",
          breadcrumbs: ["Daily Report"],
        },
      },
    ],
  },
  {
    path: "/monthlyReport",
    redirect: "/monthlyReport",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/monthlyReport",
        name: "monthlyReport",
        component: () => import("@/views/report/MonthlyReport.vue"),
        meta: {
          pageTitle: "Monthly Report",
          breadcrumbs: ["Monthly Report"],
        },
      },
    ],
  },
  {
    path: "/myPoint",
    redirect: "/myPoint",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/myPoint",
        name: "myPoint",
        component: () => import("@/views/point/MyPoint.vue"),
        meta: {
          pageTitle: "My Point",
          breadcrumbs: ["My Point"],
        },
      },
    ],
  },
  {
    path: "/hiring",
    redirect: "/hiring",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/hiring",
        name: "hiring",
        component: () => import("@/views/board/Board.vue"),
        meta: {
          pageTitle: "Hiring",
          breadcrumbs: ["hiring"],
          boardKind: 2,
        },
      },
      {
        path: "/hiring/writeDoc",
        name: "hiringWrite",
        component: () => import("@/views/board/WriteDoc.vue"),
        meta: {
          pageTitle: "Write",
          breadcrumbs: ["hiring", "Write"],
          boardKind: 2,
        },
      },
      {
        path: "/hiring/doc",
        name: "hiringPage",
        component: () => import("@/views/board/Doc.vue"),
        meta: {
          pageTitle: "Doc",
          breadcrumbs: ["hiring", "Doc"],
          boardKind: 2,
        },
      },
    ],
  },
  {
    path: "/board",
    redirect: "/board",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/board",
        name: "board",
        component: () => import("@/views/board/Board.vue"),
        meta: {
          pageTitle: "Board",
          breadcrumbs: ["board"],
          boardKind: 1,
        },
      },
      {
        path: "/board/writeDoc",
        name: "Write",
        component: () => import("@/views/board/WriteDoc.vue"),
        meta: {
          pageTitle: "Write",
          breadcrumbs: ["board", "Write"],
          boardKind: 1,
        },
      },
      {
        path: "/board/doc",
        name: "Document",
        component: () => import("@/views/board/Doc.vue"),
        meta: {
          pageTitle: "Doc",
          breadcrumbs: ["board", "Doc"],
          boardKind: 1,
        },
      },
    ],
  },
  {
    path: "/statistics",
    redirect: "/statistics",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/statistics/monthly",
        name: "monthly statistics",
        component: () => import("@/views/statistics/MonthlyStatistics.vue"),
        meta: {
          pageTitle: "Monthly Statistics",
          breadcrumbs: ["Statistics", "Monthly Statistics"],
        },
      },
      {
        path: "/statistics/job",
        name: "job statistics",
        component: () => import("@/views/statistics/JobStatistics.vue"),
        meta: {
          pageTitle: "Job Statistics",
          breadcrumbs: ["Statistics", "Job Statistics"],
        },
      },
    ],
  },
  {
    path: "/configuration",
    redirect: "/configuration",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/configuration",
        name: "configuration",
        component: () => import("@/views/configuration/Configuration.vue"),
        meta: {
          pageTitle: "Configuration",
          breadcrumbs: ["Configuration"],
        },
      },
    ],
  },
  {
    path: "/moneyCheck",
    redirect: "/moneyCheck",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/moneyCheck",
        name: "moneyCheck",
        component: () => import("@/views/moneycheck/MoneyCheck.vue"),
        meta: {
          pageTitle: "Money Check",
          breadcrumbs: ["Money Check"],
        },
      },
    ],
  },
  {
    path: "/pointCheck",
    redirect: "/pointCheck",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/pointCheck",
        name: "pointCheck",
        component: () => import("@/views/pointCheck/PointCheck.vue"),
        meta: {
          pageTitle: "Point Check",
          breadcrumbs: ["Point Check"],
        },
      },
    ],
  },
  {
    path: "/monthSummary",
    redirect: "/monthSummary",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/monthSummary",
        name: "monthSummary",
        component: () => import("@/views/point/MonthSummary.vue"),
        meta: {
          pageTitle: "Month Summary",
          breadcrumbs: ["Month Summary"],
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  store
    .dispatch(Actions.VERIFY_AUTH, { apiToken: JwtService.getToken() })
    .then(() => {
      // before page access check if page requires authentication
      if (to.meta.middleware == "auth") {
        if (store.getters.isUserAuthenticated) {
          next();
        } else {
          next({ name: "sign-in" });
        }
      } else {
        next();
      }
    });

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
