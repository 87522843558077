import { createI18n } from "vue-i18n";
import en from "./en.json";
import kr from "./kr.json";
import jp from "./jp.json";
import fr from "./fr.json";

const messages = {
  en,
  kr,
  jp,
  fr,
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
